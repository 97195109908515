<template>
  <v-container fluid class="py-0 align-start fill-height">
    <v-navigation-drawer app right :width="show_filters ? 380 : 50">
      <template v-if="show_filters">
        <v-row>
          <v-col cols="12">
            <v-card tile elevation="0">
              <v-card-text class="pa-4">
                <v-row class="dense-wrapper">
                  <v-col cols="9" class="font-weight-bold pb-0" style="padding-bottom: 0 !important; line-height: 2.6rem">
                    Відбір абонентів
                  </v-col>
                  <v-col cols="3" style="position:relative;">
                    <v-btn icon @click="show_filters = !show_filters"
                           class="grey lighten-4"
                           style="position:absolute; top: 0; right: 14px">
                      <v-icon color="success">
                        mdi-menu-close
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="mt-2">
                    <AddressElementSelect
                        label="Об’єднана громада" v-model="filters.amalgamated_hromada_id"
                        filled select_type="amalgamated_hromada"
                    />
                  </v-col>
                  <v-col cols="12">
                    <AddressElementSelect
                        label="Населений пункт" v-model="filters.city_id"
                        :parent_id="filters.amalgamated_hromada_id" :use_parent_id="true"
                        filled select_type="city"
                    />
                  </v-col>
                  <v-col cols="12">
                    <AddressElementSelect
                        label="Район міста" v-model="filters.city_area_id"
                        :parent_id="filters.city_id" :use_parent_id="true"
                        filled select_type="city-area"
                    />
                  </v-col>
                  <v-col cols="12" v-if="filters.city_area_id">
                    <AddressElementSelect
                        label="Вулиця" v-model="filters.streets" multiple
                        :parent_id="filters.city_area_id" :use_parent_id="true"
                        filled select_type="street-with-city-area"
                    />
                  </v-col>
                  <v-col cols="12" v-else>
                    <AddressElementSelect
                        label="Вулиця(-ці)" v-model="filters.streets" multiple
                        :parent_id="filters.city_id" :use_parent_id="true"
                        filled select_type="street"
                    />
                  </v-col>
                  <v-col cols="12">
                    <AddressElementSelect
                        label="Будинок(-ки)" v-model="filters.buildings" multiple
                        :parent_id="filters.streets" :use_parent_id="true"
                        filled select_type="building"
                    />
                  </v-col>
                  <v-col cols="12">
                    <Checker :value="filters.checker_id" @autocompleteChange="onCheckerChange"/>
                  </v-col>
                  <v-col cols="12">
                    <v-select :items="phone_filters_select"
                              v-model="filters.phone_filter"
                              hide-details color="grey"
                              filled label="Наявність телефонів"
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-switch
                        v-model="filters.debt_by_end_balance_minus_pay"
                        inset
                        hide-details
                        color="success"
                        class="mt-0 ml-2"
                        :label="filters.debt_by_end_balance_minus_pay ? 'Відбір по кін.сальдо з урах.оплати (включений)' : 'Відбір по кін.сальдо з урах.оплати (виключений)'"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field filled :disabled="!filters.debt_by_end_balance_minus_pay"
                                  label="Сума від" hide-details color="grey"
                                  v-model="filters.debt_by_end_balance_minus_pay_start"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field filled :disabled="!filters.debt_by_end_balance_minus_pay"
                                  label="Сума до" hide-details color="grey"
                                  v-model="filters.debt_by_end_balance_minus_pay_end"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="success" block @click.stop="fillFlatTable"
                           :loading="data_loading"
                    >
                      Заповнити
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="grey lighten-4" block>Очистити</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row justify="center" align="center">
          <v-col cols="12" class="d-flex align-center justify-center">
            <v-btn icon @click="show_filters = !show_filters" class="mt-3 grey lighten-4">
              <v-icon color="success">
                mdi-menu-open
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
    <v-row style="height: 100vh">
      <v-col cols="12" md="6">
        <v-card class="mt-3 pa-3" style="height: calc(100vh - 25px); overflow: auto">
          <v-card v-for="item in items" :key="item.person_hash" flat
                  @click.stop="fillDataByFlat(item)"
                  class="px-3 py-2 address-item-card">
            <div class="address-item">
              <div class="address-item-left">
                {{ item.full_address }}
              </div>
              <div class="address-item-middle">
                {{ item.flat_owner_represent_full }}
              </div>
              <div class="address-item-right font-weight-medium"
                   :class="[
                                {'error--text': item.balance > 0},
                                {'success--text': item.balance < 0}
                            ]"
              >
                {{ item.balance | formatToFixed(2) }}
              </div>
            </div>
          </v-card>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-dialog persistent fullscreen v-if="show_dialog" v-model="show_dialog">
          <HousesPayWorkPlace :modal_item="true"
                              :modal_person_hash="dialog_person_hash"
                              @closeWorkPlaceModalItem="closeWorkPlaceModalItem"
          />
        </v-dialog>

        <v-card class="mt-3 pa-3" style="height: calc(100vh - 25px); overflow: hidden; position: relative">
          <div class="d-flex">
            <div style="flex: 1">
              <v-text-field v-model="message_text"
                            color="grey" style="line-height: 1rem"
                            hide-details clearable filled
                            class="mr-2"
                            placeholder="Введіть текст повідомлення/дзвінка"
              />
            </div>
            <div style="flex: 0 0 170px">
              <v-select filled placeholder="Статус"
                        v-model="message_status" hide-details
                        :items="flat_note_result_type_select"
                        :disabled="!message_text"
                        color="grey" class="mr-2"
              />
            </div>
            <div style="flex: 0 0 64px">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn height="46" width="64"
                         color="grey lighten-4" depressed
                         v-bind="attrs"
                         v-on="on"
                         @click="saveHistory"
                         :disabled="!message_text"
                  >
                    <v-icon size="30" color="grey darken-1">
                      mdi-content-save-check-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Записати повідомлення/дзвінок у журнал подій</span>
              </v-tooltip>
            </div>
          </div>
          <div class="detail-address-item-wrapper"
               style="height: calc(100vh - 128px); overflow: auto;">
            <v-card v-for="item in detail_items" :key="item.person_hash"
                    :disabled="detail_loading"
                    class="detail-address-item mx-1"
                    elevation="2">
              <div class="detail-address-item-left">
                <v-checkbox v-model="item.active"
                            @change="getSelectedTotals"
                            hide-details class="mt-1 pt-0 px-1" color="success"/>
              </div>
              <div class="detail-address-item-middle">
                <div class="detail-address-item-middle-item">
                  <div class="detail-address-item-middle-item-left">
                    Адреса
                  </div>
                  <div class="detail-address-item-middle-item-right">
                    {{ item.address }}
                  </div>
                </div>
                <div class="detail-address-item-middle-item">
                  <div class="detail-address-item-middle-item-left">
                    Власник
                  </div>
                  <div class="detail-address-item-middle-item-right">
                    {{ item.owner }}
                  </div>
                </div>
                <div class="detail-address-item-middle-item">
                  <div class="detail-address-item-middle-item-left">
                    Телефон
                  </div>
                  <div class="detail-address-item-middle-item-right">
                    {{ item.phone }}
                  </div>
                </div>
                <div class="detail-address-item-middle-item">
                  <div class="detail-address-item-middle-item-left">
                    Сальдо
                  </div>
                  <div class="detail-address-item-middle-item-right">
                    {{ item.balance | formatToFixed(2) }} грн.
                  </div>
                </div>
                <div class="detail-address-item-middle-item">
                  <div class="detail-address-item-middle-item-left">
                    Коментар
                  </div>
                  <div class="detail-address-item-middle-item-right">
                    {{ item.comments }}
                  </div>
                </div>
              </div>
              <div class="detail-address-item-right">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn height="30" width="64"
                           color="grey lighten-3" depressed style="margin-bottom: 6px;"
                           v-bind="attrs"
                           v-on="on"
                           @click="sendMessageQuestion(item)"
                    >
                      <v-icon color="grey darken-1">mdi-email-fast-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Надіслати SMS</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn height="30" width="64" color="grey lighten-3"
                           depressed style="margin-bottom: 6px;"
                           v-bind="attrs"
                           v-on="on"
                           @click="openWorkPlace(item)"
                    >
                      <v-icon color="grey darken-1">mdi-eye-settings-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Відкрити картку абонента</span>
                </v-tooltip>
              </div>
            </v-card>
          </div>

          <v-card style="position: absolute; bottom: 1px; left: 0; right: 0; border-top: 2px solid #d9d9d9;"
                  class="py-2 px-3 mx-3">
            <div class="d-flex align-center">
              <div style="flex: 1 1 50%; display: flex; align-items: center; justify-content: center">
                <v-icon size="26" color="secondary" class="mr-4">
                  mdi-account-multiple-check-outline
                </v-icon>
                <div class="font-weight-medium" style="font-size: 1rem">
                  {{ totals.count }}
                </div>
              </div>
              <div
                  style="flex: 1 1 50%; display: flex; align-items: center; justify-content: center; border-left: 1px solid #d9d9d9">
                <v-icon size="26" color="secondary" class="mr-4">
                  mdi-sigma
                </v-icon>
                <div class="font-weight-medium" style="font-size: 1rem">
                  {{ totals.balance | formatToFixed(2) | formatNumber }} грн.
                </div>
              </div>
            </div>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import debtWorkCallAPI from "@/utils/axios/debt_work_call"
import {ALERT_SHOW} from "@/store/actions/alert";
import {flat_note_result_type_select} from "@/utils/icons"
import {mapGetters} from "vuex";
import {QUESTION_SHOW} from "@/store/actions/question";
import smsSendConfigAPI from "@/utils/axios/sms_send_config";

export default {
  name: "DebtWorkCalls",
  components: {
    AddressElementSelect: () => import("@/components/autocomplite/AddressElementSelect.vue"),
    Checker: () => import("@/components/autocomplite/Checker"),
    HousesPayWorkPlace: () => import("@/components/HousePayWorkPlace.vue"),
  },
  data() {
    return {
      flat_note_result_type_select,
      data_loading: false,
      detail_loading: false,
      filters: {
        checker_id: null,
        amalgamated_hromada_id: null,
        city_id: null,
        city_area_id: null,
        streets: [],
        buildings: [],
        debt_by_end_balance_minus_pay: false,
        debt_by_end_balance_minus_pay_start: null,
        debt_by_end_balance_minus_pay_end: null,
        phone_filter: 'with_phones'
      },
      phone_filters_select: [
        {text: 'Усі', value: 'all'},
        {text: 'З телефоном(-ми)', value: 'with_phones'},
        {text: 'Без телефона(-ів)', value: 'without_phones'},
      ],
      items: [],
      detail_items: [],
      totals: {
        count: 0,
        balance: 0
      },
      message_text: null,
      message_status: 'positive',
      show_filters: true,
      show_dialog: false,
      dialog_person_hash: null,
      question_watcher: null,
    }
  },
  computed: {
    ...mapGetters({
      modalAnswer: 'question_answer',
      settings: 'getGeneralSettings'
    })
  },
  methods: {
    watch_modal_answer() {
      this.question_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === 'debt_work_send_sms_question') {
                if (payload.answer) {
                  const template_id = this.settings?.general_sms_send_template?.value || null
                  const config_id = this.settings?.general_sms_send_config?.value || null

                  if (template_id && config_id) {
                    const message = {
                      text: '',
                      phone: payload.payload.phone,
                      flat_id: payload.payload.flat_id,
                      debt: payload.payload.balance
                    }

                    smsSendConfigAPI.send_sms_with_send_config(
                        {
                          write_to_history: true,
                          template_id: template_id,
                          messages: [message]
                        })
                        .then(response => response.data)
                        .then(data => {
                          const text = data.success ? 'Повідомлення успішно відправлено' : 'Повідомлення не відправлено'
                          const color = data.success ? 'success' : 'error'

                          this.$store.commit(ALERT_SHOW, {text: text, color: color})
                        })
                        .catch(err => {
                          const error = err.response.data.detail;
                          this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                        })

                  } else {
                    this.$store.commit(ALERT_SHOW,
                        {
                          text: 'Для відправлення смс необхідно вказати типове налаштування сервісу та типовий шаблон',
                          color: 'error'
                        })
                  }
                }
              }
            }
          }
      )
    },
    sendMessageQuestion(payload) {
      const question_payload = {
        text: `Надіслати смс за адресою: ${payload.address} (${payload.phone})`,
        accept_button: true,
        id: 'debt_work_send_sms_question',
        payload: JSON.parse(JSON.stringify(payload))
      }
      this.$store.dispatch(QUESTION_SHOW, question_payload)
    },
    onCheckerChange(payload) {
      this.filters.checker_id = (payload || {}).value || null
    },
    fillFlatTable() {
      this.data_loading = true
      this.detail_items = []
      debtWorkCallAPI.fill_table(Object.assign({}, this.filters))
          .then(response => response.data)
          .then(data => {
            this.items = data
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
          .finally(() => {
            this.data_loading = false
            this.show_filters = false
            this.getSelectedTotals()
          })

    },
    getOtherData(payload) {
      this.detail_loading = true
      debtWorkCallAPI.fill_other(payload)
          .then(response => response.data)
          .then(data => {
            data.forEach(item => {
              this.detail_items.push(item)
            })
            this.getSelectedTotals()
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
          .finally(() => {
            this.detail_loading = false
          })
    },
    getSelectedTotals() {
      this.$nextTick(() => {
        this.totals = this.detail_items.filter(i => i.active).reduce((acc, item) => {
          return {count: acc.count + 1, balance: acc.balance + item.balance}
        }, {count: 0, balance: 0})
      })
    },
    fillDataByFlat(payload) {
      this.detail_items = []

      this.detail_items.push(
          {
            address: payload.full_address,
            owner: payload.flat_owner_represent_full,
            phone: payload.phone_number,
            balance: payload.balance,
            person_id: payload.person_id,
            person_hash: payload.person_hash,
            flat_id: payload.id,
            comments: payload.comments,
            active: true
          }
      )

      this.getOtherData({flat_id: payload.id, owner: payload.flat_owner_represent_full})
    },
    openWorkPlace(item) {
      this.dialog_person_hash = item.person_hash
      this.show_dialog = true
      // let resolvedRoute = this.$router.resolve({
      //   name: 'WorkPlace',
      //   params: {person_hash: item.person_hash}
      // });
      // window.open(resolvedRoute.href, '_blank');
    },
    closeWorkPlaceModalItem() {
      this.show_dialog = false
      this.dialog_person_hash = ''
    },
    saveHistory() {
      if (!this.message_text) {
        this.$store.commit(ALERT_SHOW, {text: 'Не вказаний текст повідомлення/дзвінка', color: 'error'})
        return
      }

      const payload = this.detail_items.map(item => {
        return {
          flat_id: item.flat_id,
          message_status: this.message_status,
          balance: item.balance,
          message_text: this.message_text
        }
      })

      debtWorkCallAPI.create_history(payload)
          .then(response => response.data)
          .then(data => {
            this.$store.commit(ALERT_SHOW, {text: `Успішно створено ${data} записи історії`, color: 'success'})
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    }
  },
  created() {
    this.watch_modal_answer()
  },
  beforeDestroy() {
    if (this.question_watcher) {
      this.question_watcher()
    }
  }
}
</script>

<style scoped lang="scss">

.address-item-card {
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0 !important;
  cursor: pointer;

  &:hover {
    background-color: #ececec;
  }

  &:nth-child(1) {
    border-top: 1px solid #e0e0e0;
  }
}

.address-item {
  display: flex;
  flex-wrap: nowrap;
  font-size: .834rem;
  cursor: pointer;
  line-height: .9rem;

  .address-item-left {
    flex: 1;
  }

  .address-item-middle {
    flex: 0 0 36%;
    border-left: 2px dotted #e0e0e0;
    text-align: left;
    padding-left: 6px;
  }

  .address-item-right {
    flex: 0 0 100px;
    border-left: 2px dotted #e0e0e0;
    text-align: right;
    padding-right: 3px;
  }
}

.detail-address-item-wrapper {
  margin-top: 16px;

  .detail-address-item {
    display: flex;
    flex-wrap: nowrap;
    padding: 8px;
    margin-bottom: 10px;

    .detail-address-item-left {
      flex: 0 0 48px;
      padding-left: 4px;
    }

    .detail-address-item-middle {
      flex: 1;

      .detail-address-item-middle-item {
        display: flex;
        margin-bottom: 6px;

        .detail-address-item-middle-item-left {
          flex: 0 0 90px;
          line-height: 30px;
          font-size: .82rem;
          font-weight: 500;
          background-color: #e7e7e7;
          text-align: center;
          color: #2f2f2f;
        }

        .detail-address-item-middle-item-right {
          flex: 1;
          padding-left: 12px;
          padding-right: 12px;
          padding-top: 8px;
          padding-bottom: 8px;
          line-height: 14px;
          font-size: .9rem;
          background-color: #fafafa;
          min-height: 30px;
        }
      }
    }

    .detail-address-item-right {
      flex: 0 0 72px;
      padding-left: 8px;
    }
  }
}
</style>