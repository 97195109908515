import { render, staticRenderFns } from "./DebtWorkCalls.vue?vue&type=template&id=52088b22&scoped=true&"
import script from "./DebtWorkCalls.vue?vue&type=script&lang=js&"
export * from "./DebtWorkCalls.vue?vue&type=script&lang=js&"
import style0 from "./DebtWorkCalls.vue?vue&type=style&index=0&id=52088b22&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52088b22",
  null
  
)

export default component.exports