import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/debt-work-call';

export default {
    fill_table(payload) {
        return axios.post(`${RESOURCE_NAME}/fill-table`, payload)
    },
    fill_other(payload) {
        return axios.post(`${RESOURCE_NAME}/fill-other`, payload)
    },
    create_history(payload) {
        return axios.post(`${RESOURCE_NAME}/create-history`, payload)
    },
};